import React from 'react';
import { Upload, Modal, Input } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const MyContext = React.createContext();


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.addEventListener('load', () => reject(reader.result));
    reader.onerror = error => reject(error);
  });
}


class GalleryImgUp extends React.Component {


  state = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    loading: false,
    fileList: [],
    motailList: [],
    motailValue: "",
    motailItemUid: "",
    motailKey: 1,
  };


  handleCancel = () => this.setState({
    previewVisible: false,
    motailKey: 2,
    motailValue: ""
  });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    let item = this.state.motailList.filter(item => item.file.uid === file.uid)
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      motailValue: item.length ? item[0].content : "",
      motailItemUid: file.uid
    });
  };

  handleChange = ({ fileList, file }) => {
    let motailArr = JSON.parse(JSON.stringify(this.state.motailList))
    if (fileList.length < this.state.fileList) {
      motailArr.forEach((item, index) => {
        if (item.file.uid === file.uid) {
          motailArr.splice(index, 1)
        }
      })
    } else if (fileList.length > this.state.fileList.length) {
      if (motailArr.length !== 0) {
        motailArr.forEach(item => {
          fileList.forEach(itemA => {
            if (item.file.uid !== itemA.uid) {
              motailArr.push({
                content: '',
                file: itemA
              })
            }
          })
        })
      } else {
        fileList.forEach(item => {
          motailArr.push({
            contnet: "",
            file: item
          })
        })
      }
    }
    this.setState({
      motailList: motailArr
    },()=>{
      this.props.getChildData(this.state.motailList)
    })
    this.setState({ fileList })
  };

  render() {
    const { loading, previewVisible, previewImage, fileList, previewTitle, motailValue } = this.state;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined style={{ fontSize: '32px', color: '#666' }} />}
        {/* <PlusOutlined /> */}
        <div style={{ marginTop: 8 }}>添加作品</div>
      </div>
    );

    return (
      <div>
        <MyContext.Consumer>
          {value => this.state.motailValue}
        </MyContext.Consumer>
      </div>,

      <div>
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={(file) => {
            let motailFileList = JSON.parse(JSON.stringify(this.state.motailList))
            motailFileList = motailFileList.filter(item => file.uid !== item.file.uid)
            this.setState({
              motailList: motailFileList
            },()=>{
              this.props.getChildData(this.state.motailList)
            })
          }}
          showUploadList={true}
          multiple={true}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>

        <Modal
          key={this.state.motailKey}
          visible={previewVisible}
          title={previewTitle}
          // footer={[
          //   <Button key="back" onClick={this.handleCancel}>
          //     取消
          //   </Button>,
          //   <Button key="submit" type="primary" onClick={this.handleOk}>
          //     保存
          //   </Button>,
          // ]}
          onCancel={this.handleCancel}
          onOk={() => {
            let fileListArr = JSON.parse(JSON.stringify(this.state.motailList))
            fileListArr.forEach(item => {
              if (item.file.uid === this.state.motailItemUid) {
                item.content = this.state.motailValue
              }
            })
            this.setState({
              motailList: fileListArr,
              previewVisible: false,
              motailKey: ''
            },()=>{
              this.props.getChildData(this.state.motailList)
            })
          }}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
          <div style={{ marginTop: "10px" }}>
            <TextArea
              rows={4}
              placeholder="添加作品背景故事"
              value={this.state.motailValue}
              onChange={(e) => {
                this.setState({
                  motailValue: e.target.value
                })
              }} />
          </div>
        </Modal>
      </div>
    );

  }

}

export default GalleryImgUp;
