import AV from 'leancloud-storage';


export function processChallenges(challenges, baseUrl) {
  var processedchallenges = [];
  for (let i=0; i<challenges.length; i++) {
    processedchallenges.push(challenges[i]['attributes']);
    processedchallenges[i]['url'] = baseUrl + challenges[i]['id'];
    processedchallenges[i]['objectId'] = challenges[i]['id'];
    processedchallenges[i]['items'] = 0;
    processedchallenges[i]['views'] = 0;
    processedchallenges[i]['initialIndex'] = challenges[i]['attributes']['index'];
  }
  return processedchallenges;
}

export function saveSort(tableData, ) {
  return new Promise((resolve, reject) => {
    let initIndexSortedData = tableData.slice();
    initIndexSortedData.sort((a, b) => a['initialIndex'] - b['initialIndex']);  // 获取最初最小index
    let sortedData = tableData.slice();
    // console.log('initialData', initialData);
    sortedData.sort((a, b) => a['index'] - b['index']);  // 用于按顺序更新index
    console.log('dataCopy', sortedData);

    let indexDelta = 0;  // 平移距离
    if (initIndexSortedData[0]['initialIndex'] < sortedData.length) {
      indexDelta = sortedData.length;  // 平移到N+1开始
    } else if (initIndexSortedData[0]['initialIndex'] > sortedData.length) {
      // updateIndex已经自动从1分配了index
    }
    console.log('indexDelta:', indexDelta);

    for (var i=0; i<sortedData.length; i++) {
      const challengeObject = AV.Object.createWithoutData('Challenge', sortedData[i]['objectId']);  // 获取挑战
      const newIndex = sortedData[i]['index'] + indexDelta;
      challengeObject.set('index', newIndex);  // 更新该挑战index
      sortedData[i]['initialIndex'] = newIndex;  // 更新本地挑战index
      console.log('challengeObject index', sortedData[i]['index'], '=>', sortedData[i]['index'] + indexDelta);
      if (challengeObject.dirtyKeys()) {  // 如果index与更新前不同，
        challengeObject.save().then(() => {  // 保存
          if (i === sortedData.length) {
            resolve(sortedData);
          }
          console.log('SaveSort', i, '/', sortedData.length)  // Eg, SaveSort 1/8
        }, error => {
          {reject(error);return;};
        });
      } else if (i === sortedData.length) {
        resolve(sortedData);
      }
    }
  });
}

async function deleteCommentsOf(creation) {
  return new Promise(async (resolve, reject) => {
    const commentQuery = new AV.Query('Comment');
    commentQuery.equalTo('targetCreation', creation);
    const comments = await commentQuery.find().catch(reject);
    await AV.Object.destroyAll(comments).catch(reject);
    console.log('Comments deleted for', creation);
    resolve();
  })
}

export function unlistChallenge(objectId) {
  return new Promise(async (resolve, reject) => {
    // reject = error => {throw error};  // For debugging
    const challengeQuery = new AV.Query('Challenge');
    challengeQuery.include('picture');
    const challengeToBeUnlisted = await challengeQuery.get(objectId).catch(reject);
    console.log('Challenge to be unlisted:', challengeToBeUnlisted);
    const imageFile = await challengeToBeUnlisted.get('picture');
    await imageFile.destroy().catch(reject);
    console.log('Image file deleted');

    const creationQuery = new AV.Query('Creation');
    creationQuery.equalTo('challenge', challengeToBeUnlisted);
    const creations = await creationQuery.find().catch(reject);
    for (const creation of creations) {
      await deleteCommentsOf(creation);
    }
    await AV.Object.destroyAll(creations).catch(reject);
    console.log('creations deleted')
    await challengeToBeUnlisted.destroy().catch(reject);
    console.log('challenge deleted');
    resolve();    
  }); // return语句结束
}
