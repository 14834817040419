//author: Mingyang SUN , oct 10 2020
import React, {Component} from 'react';
import "../css/report_pool_creation.css";
const AV=require("leancloud-storage");

class ReportPoolCreation extends Component {
    constructor(props){
        super(props);
        this.state={
            data:"",
            error:""

        }

    }

    componentDidMount() {
      this.getData();
    }

    getData= async ()=>{
        const {data,contentId}=this.props;
        if (data===null || contentId===null){
            this.setState({error:"Error in getting content from database"})
        } else{
            const query = new AV.Query('Creation');
            await query.get(contentId)
                .then((msg)=>{
                    this.setState({data:msg},()=>{
                        // console.log(this.state.data)
                    })
                })
                .catch((e)=>{
                    console.log("something wrong in getting creation"+e)
                    this.setState({error:"Error in getting content from database"})
                })
        }


    }

    render() {
        //content from parent component
        const data= this.state.data["_serverData"];
        const {error}=this.state;

        return (
            <div className="report_artwork_container">
                { data||"".length>0?(
                    <>
                    <div className="report_artwork_img">
                        {
                            data.urls&&
                                data.urls.map((data,index)=>{
                                    return (
                                        <img src={data} key={index} alt={index}/>
                                    )
                                })

                        }

                    </div>

                    <div className="report_artwork_text">
                    <p><span>作品描述: </span>
                        {data.title?data.title:"no title in here"}
                    </p>
                    <div className="line"/>
                    <p><span>作品背景故事: </span>{data.content?data.content:"no content in here"}
                    </p>

                    </div>
                    </>
                ):error
                    ? error
                    :"loading"}

            </div>
        );
    }
}

export default ReportPoolCreation;
