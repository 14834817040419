//author: Mingyang SUN , oct 10 2020

import React, {Component} from 'react';
import { Modal, Button } from 'antd';
import "../css/report_pool_detail.css";
import {
    CloseOutlined
} from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import {Report} from "../setting";
import ReportPoolDiary from "../components/report_pool_diary";
import ReportPoolComment from "../components/report_pool_comment";
import ReportPoolCreation from "../components/report_pool_creation";
import {send_msg_after_offshelf} from "../services/report_pool_service";

const AV = require('leancloud-storage/live-query');

class ReportPoolDetailPage extends Component {
    constructor(props){
        super(props);
        this.state={
            visible: false,
            button:null,
            id:this.props.location.state.id,
            category:this.props.location.state.category,
            pageNumber:this.props.location.state.pageNumber,
            number:this.props.location.state.number,
            showLastOneBtn:true,
            showNextOneBtn:true,
        };
        this.reject=this.reject.bind(this);
        this.offShelf=this.offShelf.bind(this);
        this.lastOne=this.lastOne.bind(this);
        this.nextOne=this.nextOne.bind(this);
    }

    componentDidMount() {
        this.detectBtn();
    }

    showRejectModal = () => {
        this.setState({
            visible: true,
            button:"reject"
        });
    };

    showOffShelfModal = () => {
        this.setState({
            visible: true,
            button:"offShelf"
        });
    };

    reject=()=>{
        const{id}=this.state;
        //database
        const report=AV.Object.createWithoutData(Report,id);
        //Test database
        // const report=AV.Object.createWithoutData("TestReport",id);
        report.set("status",3);
        report.save();
    };

    offShelf=()=>{
        const{id}=this.state;
        const {reportedId,status}=this.props.location.state;

        if (status!==2){
            //database
            const report=AV.Object.createWithoutData(Report,id);
            report.save({
                status:2
            }).catch((e)=>{
                console.log("Errors in setting status to offshelf"+e)
            });

            //add information to message database
            send_msg_after_offshelf(reportedId);
        } else {
            console.log("Already offshelf")
        }
    };

    hideModal = () => {
        this.setState({
            visible: false,
        });
    };

    //When user click either 驳回 or 下架
    //the status will be changed
    manageStatus=()=>{
        this.setState({
            visible: false,
        });
        const {button}=this.state;
        if (button==="reject") this.reject();
        if (button==="offShelf") this.offShelf();
    };

    //when user click the first or the last info directly from the reportpool page
    //the 上一条 btn or 下一条 btn will be hidden
    detectBtn=()=>{
        const {number,list}=this.props.location.state;

        if (number===1){
            this.setState({showLastOneBtn:"hidden",showNextOneBtn:true})
        } else if (number===list.length){
            this.setState({ showLastOneBtn:true,showNextOneBtn:"hidden"})
        } else {
            this.setState({ showLastOneBtn:true,showNextOneBtn:true})
        }
    };

    //get the pagination number
    //so when user return back to last router
    //they will stay in the same page number of pagination
    getParentPageNumber=()=>{
        const {pageNumber}=this.state;
        this.props.history.push(
            {pathname:"/reportpool",
                state:pageNumber});
    };

    lastOne=()=>{
        //data from parent page
        const {list,number}=this.props.location.state;
        if (number<2){
            this.setState({showLastOneBtn:"hidden",showNextOneBtn:true})
        } else {
            //renew the data for the next router
            list[number-2]["list"]=list;
            this.setState({
                    contentId: list[number-2]["contentId"],
                    list:this.state.list,
                    id:list[number-2]["id"],
                    category:list[number-2]["category"],
                    showLastOneBtn:true,
                    showNextOneBtn:true
                }
            );
            this.props.history.push(
                {pathname:"/reportpool/"+list[number-2]["id"],
                    state:list[number-2]});
            //hide the button
            if(this.props.location.state.number===2){
                this.setState({showLastOneBtn:"hidden",showNextOneBtn:true})
            }
        }
    }

    nextOne=()=>{
        //data from parent page
        const {list,number}=this.props.location.state;
        if (number>list.length-1){
            this.setState({ showLastOneBtn:true,showNextOneBtn:"hidden"})
        } else {
            //renew the data for the next router
            list[number]["list"]=list;
            this.setState({
                    contentId: list[number]["contentId"],
                    list:this.state.list,
                    id:list[number]["id"],
                    category:list[number]["category"],
                    showLastOneBtn:true,
                    showNextOneBtn:true
                }
            );

            this.props.history.push(
                {pathname:"/reportpool/"+list[number]["id"],
                    state:list[number]});

            //hide the button
            if(number===list.length-1){
                this.setState({showLastOneBtn:true,showNextOneBtn:"hidden"})
            }
            console.log(this.props.location.state.number)
        }
    };

    getContainer = () => {
        return document.getElementById("rootDiv");
    };


    render() {
        const {category,id,showLastOneBtn,showNextOneBtn}=this.state;
        const {contentId}=this.props.location.state;
        // console.log(this.props.location.state)

        return (
            <div className="report_detail_container">
                <div onClick={this.getParentPageNumber} className={"close-btn"}><CloseOutlined/></div>

                <div className="report_detail_content">
                    <div className="report_detail_content_overflow">
                        {
                            category==="creation"
                                ?<ReportPoolCreation data={id} contentId={contentId}/>
                                : category==="diary"
                                ?<ReportPoolDiary data={id} contentId={contentId}/>
                                :<ReportPoolComment data={id} contentId={contentId}/>
                        }

                    </div>
                </div>

                <div className="report_detail_button">
                    <button style={{visibility:showLastOneBtn}} className="report_detail_btn_bottom" onClick={this.lastOne}>上一条</button>
                    <button onClick={this.showRejectModal}>驳回</button>
                    <button onClick={this.showOffShelfModal} className="off_shelf">下架</button>
                    <button style={{visibility:showNextOneBtn}} className="report_detail_btn_bottom" onClick={this.nextOne}>下一条</button>
                </div>
                <Modal
                    visible={this.state.visible}
                    footer={[
                        <Button key="cancel" className="report_modal_btn report_modal_confirm" onClick={this.manageStatus}>确认</Button>,
                        <Button key="ok" className="report_modal_btn report_modal_cancel" onClick={this.hideModal}>取消</Button>,
                    ]}
                    onOk={this.hideModal}
                    onCancel={this.hideModal}
                    className="report_detail_modal_container"
                    cancelText="取消"
                    okText="确认"
                    // getContainer={this.getContainer}
                >
                    {this.state.button==="reject"? <p>举报驳回后，该内容将不做处理</p>:<p>内容下架后，该内容将被删除</p>}

                </Modal>
            </div>
        );
    }
}

export default withRouter(ReportPoolDetailPage);
