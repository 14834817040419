import AV from 'leancloud-storage';


export function processTopics(topics, baseUrl) {
  var processedTopics = [];
  for (let i=0; i<topics.length; i++) {
    processedTopics.push(topics[i]['attributes']);
    processedTopics[i]['url'] = baseUrl + topics[i]['id'];
    processedTopics[i]['objectId'] = topics[i]['id'];
    processedTopics[i]['items'] = 0;
    processedTopics[i]['views'] = 0;
    processedTopics[i]['initialIndex'] = topics[i]['attributes']['index'];
  }
  return processedTopics;
}

export function saveSort(tableData, ) {
  return new Promise((resolve, reject) => {
    let initIndexSortedData = tableData.slice();
    initIndexSortedData.sort((a, b) => a['initialIndex'] - b['initialIndex']);  // 获取最初最小index
    let sortedData = tableData.slice();
    // console.log('initialData', initialData);
    sortedData.sort((a, b) => a['index'] - b['index']);  // 用于按顺序更新index
    console.log('dataCopy', sortedData);

    let indexDelta = 0;  // 平移距离
    if (initIndexSortedData[0]['initialIndex'] < sortedData.length) {
      indexDelta = sortedData.length;  // 平移到N+1开始
    } else if (initIndexSortedData[0]['initialIndex'] > sortedData.length) {
      // updateIndex已经自动从1分配了index
    }
    console.log('indexDelta:', indexDelta);

    for (var i=0; i<sortedData.length; i++) {
      const topicObject = AV.Object.createWithoutData('Topic', sortedData[i]['objectId']);  // 获取话题
      const newIndex = sortedData[i]['index'] + indexDelta;
      topicObject.set('index', newIndex);  // 更新该话题index
      sortedData[i]['initialIndex'] = newIndex;  // 更新本地话题index
      console.log('topicObject index', sortedData[i]['index'], '=>', sortedData[i]['index'] + indexDelta);
      if (topicObject.dirtyKeys()) {  // 如果index与更新前不同，
        topicObject.save().then(() => {  // 保存
          if (i === sortedData.length) {
            resolve(sortedData);
          }
          console.log('SaveSort', i, '/', sortedData.length);  // Eg, SaveSort 1/8
        }, error => {
          reject(error);
        });
      } else if (i === sortedData.length) {
        resolve(sortedData);
      }
    }
  });
}

async function deleteCommentsOf(diary) {
  return new Promise(async (resolve, reject) => {
    const commentQuery = new AV.Query('Comment');
    commentQuery.equalTo('targetDiary', diary);
    const comments = await commentQuery.find().catch(reject);
    await AV.Object.destroyAll(comments).catch(reject);
    console.log('Comments deleted for', diary);
    resolve();
  })
}

export function unlistTopic(objectId) {
  return new Promise(async (resolve, reject) => {
    // reject = error => {throw error};  // For debugging
    const topicQuery = new AV.Query('Topic');
    topicQuery.include('picture');
    const topicToBeUnlisted = await topicQuery.get(objectId).catch(reject);
    console.log('Topic to be unlisted:', topicToBeUnlisted);
    const imageFile = await topicToBeUnlisted.get('picture');
    await imageFile.destroy().catch(reject);
    console.log('Image file deleted');

    const diaryQuery = new AV.Query('Diary');
    diaryQuery.equalTo('topic', topicToBeUnlisted);
    const diaries = await diaryQuery.find().catch(reject);
    for (const diary of diaries) {
      await deleteCommentsOf(diary);
    }
    await AV.Object.destroyAll(diaries).catch(reject);
    console.log('diaries deleted')
    await topicToBeUnlisted.destroy().catch(reject);
    console.log('topic deleted');
    resolve();    
  }); // return语句结束
}