import React, { useState } from 'react';
import { Button, Modal, Table, Card, Space, Input, Upload } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import { ContentUtils } from 'braft-utils'
import 'braft-editor/dist/index.css'

const { Meta } = Card;

class DiaryAdd extends React.Component {

  state = {
    visible: false,
    editorState: "",
    content_html: "",
    contentList: [],
    title: "",
    edit: false,
    index: 0,
    modal: 0
  };

  showModal = () => {
    this.setState({
      editorState: "",
      title: "",
      modal: 1
    });
    this.setState({
      visible: true
    }, () => {
      console.log(this.state)
    })
  };

  handleOk = e => {
    if (this.state.edit) {
      let arr = JSON.parse(JSON.stringify(this.state.contentList))
      arr[this.state.index].title = this.state.title
      arr[this.state.index].content = this.state.editorState.toHTML()
      this.setState({
        visible: false,
        contentList: arr,
        title: "",
        editorState: "",
        edit: false,

      });
      return
    }
    this.setState({
      visible: false,
      contentList: [...this.state.contentList, { title: this.state.title, content: this.state.editorState.toHTML() }],
      modal: 0
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
      modal: 2
    });
  }
  handleEditorChange = editorState => {
    this.setState({ editorState })
  }
  submitContent = () => {

  }
  uploadHandler = (param) => {

    if (!param.file) {
      return false
    }

    this.setState({
      editorState: ContentUtils.insertMedias(this.state.editorState, [{
        type: 'IMAGE',
        url: URL.createObjectURL
      }])
    })

  }

  render() {
    // const { tableData } = this.state;
    const controls = ['bold', 'italic', 'underline', 'text-color', 'separator', 'clear', 'separator', 'media']
   

    return (
      <>
        {
          this.state.contentList.map((item, index) => {
            return (

              <Card
                key={index}
                title={item.title}
                style={{ width: 1000, marginTop: 16 }}
                actions={[
                  <EditOutlined key="edit" onClick={() => {
                    this.setState({
                      visible: true,
                      editorState: BraftEditor.createEditorState(item.content),
                      title: item.title,
                      edit: true
                    })
                  }} />,
                  <DeleteOutlined key="delete" onClick={() => {
                    console.log(index)
                    // return
                    let arr = JSON.parse(JSON.stringify(this.state.contentList))
                    arr.splice(index, 1)
                    this.setState({
                      contentList: [...arr]
                    })
                  }} />,
                ]}
              >
                <div dangerouslySetInnerHTML={{ __html: item.content }} style={{ height: "100px", width: "100%", overflow: "auto" }}>

                </div>
              </Card>
            )
          })
        }

        <Space size='large'>
          <Button type="primary" onClick={this.showModal}>
            新建
          </Button>
        </Space>
        <Modal
          key={this.state.modal}
          title="新建日记"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              取消
              </Button>,
            <Button key="submit" type="primary" onClick={this.handleOk}>
              保存
              </Button>,
          ]}
        >
          <Input onChange={event => { this.setState({ title: event.target.value }) }} value={this.state.title} />
          <div style={{ height: "450px", overflow: "auto" }}>
            <BraftEditor
              value={this.state.editorState}
              onChange={this.handleEditorChange.bind(this)}
              onSave={this.submitContent.bind(this)}
              controls={controls}
              media={{
                accepts:{
                  image: 'image/png,image/jpeg',
                  video: false,
                  audio: false
                },
                // 是否允许外部嵌入
                externals:{
                  image: false,
                  video: false,
                  audio: false,
                  embed: false
                }
              }}

            />
          </div>
        </Modal>
      </>
    );
  }
}


export default DiaryAdd;
