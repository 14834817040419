//author Mingyang
//transfer the time format to correct one
export const  timeFormat = (time) =>{
    const date=new Date(time);
    const y = date.getFullYear();
    const m = date.getMonth() + 1<10?("0"+date.getMonth() + 1):date.getMonth() + 1;
    const d = date.getDate()< 10 ? ('0' + date.getDate()) : date.getDate();
    const h = date.getHours()< 10 ? ('0' + date.getHours()) : date.getHours();
    const minute = date.getMinutes()< 10 ? ('0' + date.getMinutes()) : date.getMinutes();
    const second = date.getSeconds()< 10 ? ('0' + date.getSeconds()) : date.getSeconds();
    return y + '/' + m + '/' + d+' '+h+':'+minute+':'+second;
}
