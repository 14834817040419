import React from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import "./../css/img_uploader.css";


function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('只能上传JPG或PNG格式的文件！');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('图片大小必须小于2MB！');
  }
  return isJpgOrPng && isLt2M;
}



class ImgUploader extends React.Component {
  state = {
    loading: false,
    imageData: null,
    imageName: null
  };

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
  };

  loadImage(callback) {
    const { file, onSuccess } = callback;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', () => {
      const imageData = reader.result;
      this.setState({
        imageData,
        imageName: file.name,
        loading: false,
      });
      onSuccess();
      console.log(this.state)
    });
  }

  hasChosenImage() {
    return !!this.state.imageData;
  }

  render() {
    const { loading, imageData } = this.state;
    const { placeholdText } = this.props;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined style={{ fontSize: '32px', color: '#666' }} />}
        <div style={{ marginTop: 8 }}>{placeholdText || '添加海报'}</div>
      </div>
    );
    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        customRequest={(callback) => this.loadImage(callback)}
        onChange={this.handleChange}
      >
        {imageData ? <img src={imageData} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
      </Upload>
    );
  }
}

export default ImgUploader;