//author: Mingyang SUN

import {timeFormat} from "../functions/time_format";

//get the user list without violations number
//also set the violations number to 0 as default
export const userList=(data)=>{

    const list=[];
    for (let i =0;i<data.length;i++){
        const obj={
            "id":data[i]["id"],
            "email":data[i].getEmail(),
            "username":data[i].getUsername(),
            "registered-date" :timeFormat(data[i]["createdAt"]),
            "last-active":timeFormat(data[i]["updatedAt"]),
             "violations":0,
        }
        list.push(obj)
    }

    return list

}

//retrieve the status from User_Data database
export const retrieveUserStatus=(res,list)=>{

    for (let i = 0; i < list.length; i++) {
        if (i < res.length) {   // TODO: User_Data表实际未构造
            const status = list.map(obj => obj.id).indexOf(res[i]["id"])
            if (status !== -1) {
                list[status]['status'] = res[i]["_serverData"]["status"];
            }
        }
    }

    return list;
}

//update the violations number List
export const violationsList=(res)=>{
    const violatedList=[];
    for (let j=0;j<res.length;j++){
        if (res[j]["_serverData"]["status"]&&res[j]["_serverData"]["reported"]["id"]&&res[j]["_serverData"]["status"]===2){
            violatedList.push(res[j]["_serverData"]["reported"]["id"])
        }
    }
    return violatedList;
}



