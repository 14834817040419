import React  from 'react';
import ImgUploader from '../components/img_uploader';
import GalleryImgUp from '../components/galleryImpUp';
import DiaryAdd from '../components/diaryAdd';
import { Layout, Tabs, Divider, Form, Input, Button, Space, Row, Col, message } from 'antd';
import AV from 'leancloud-storage';

import "./../css/img_uploader.css";



const { TextArea } = Input;
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}



class GalleryPubPage extends React.Component {
  formRef = React.createRef();  // 用于清空表格
  imgUploadRef = React.createRef();  // 用于检查封面选择
  state = {
    current: 'add_artwork',
    motailList: []
  };

  getData = (data)=>{
    this.setState({
      motailList:data
    },()=>{
      console.log(this.state.motailList)
    })
  }

  handleClick = e => {
    console.log('click ', e);
    this.setState({ current: e.key });
  };

  onReset() {
    this.formRef.current.resetFields();  // 清除所有输入的内容
  }


  checkCover() {
    // 检查是否选择了封面
    if (this.imgUploadRef.current.hasChosenImage()) {
      return true;
    } else {
      message.warn('请选择封面图');
      return false;
    }
  }

  onFinish() {
    if (this.checkCover()) {  // 如果选择了封面
      this.setState({ submitButtonLoading: true });

      const newMuseum = new AV.Object('Museum');
      const { imageData, imageName } = this.imgUploadRef.current.state;
      const imgFile = new AV.File(imageName, { base64: imageData });

      const newCreation = new AV.Object('Creation');


      imgFile.save().then((file) => {
        newMuseum.set('name', this.valueOfField('name'));
        newMuseum.set('description', this.valueOfField('description'));
        newMuseum.set('picture', file);
        newMuseum.save().then((todo) => {
          this.setState({ submitButtonLoading: false });
          window.location.reload();  // 刷新页面
        }, error => {
          message.error('发布时出现错误：' + error, 6);
          this.setState({ submitButtonLoading: false });
        });
        newCreation.set('title', this.valueOfField('title'));
        newCreation.set('content', this.valueOfField('content'));
        newCreation.set('picture', file);
        newCreation.set('creation', newCreation);
        newCreation.save().then(() => {
          this.setState({ submitButtonLoading: false });
          window.location.reload();  // 刷新页面
        }, error => {
          message.error('发布时出现错误：' + error, 7);
          this.setState({ submitButtonLoading: false });
        });
      }, error => {
        message.error('上传图片时出现错误：' + error, 6);
        this.setState({ submitButtonLoading: false });
      });
    } else { return }  // 如果没选择封面，则不存储数据
  }

  valueOfField(fieldName) {  // Syntax sugar
    return this.formRef.current.getFieldValue(fieldName);
  }

  render() {
    return (
      <Layout>
        <br />
        <Tabs centered defaultActiveKey="1" onChange={callback}>
          <TabPane tab="添加作品" key="1">
            <div className='container'>
              <div className='form'>
                <Form.Item>
                  <GalleryImgUp getChildData={this.getData.bind(this)}>
                  </GalleryImgUp>
                </Form.Item>
              </div>
              <Divider />
              <div className='form'>
                <ImgUploader placeholdText="添加封面图" ref={this.imgUploadRef}></ImgUploader>
                <Form
                  ref={this.formRef}
                  onFinish={() => this.onFinish()}
                  onFinishFailed={() => this.checkCover()}  //只要点击提交按钮，就要同时检查文本输入和封面上传框
                >
                  <Form.Item
                    name="name"
                    label="专辑名称："
                    rules={[
                      {
                        required: true,
                        message: "请输入专辑名称"
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="专辑介绍："
                    rules={[
                      {
                        required: true,
                        message: '请输入专辑介绍'
                      }
                    ]}
                  >
                    <TextArea rows={5} />
                  </Form.Item>
                  <Form.Item name="action">
                    <Row>
                      <Col span={2} offset={20}>
                        <Space>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.submitButtonLoading}
                          >
                            发布
                      </Button>
                          <Button
                            htmlType="button"
                            onClick={() => this.onReset()}
                          >
                            取消
                      </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </TabPane>


          <TabPane tab="添加日记" key="2">


            <div centered className='container'>
              <div centered className='form'>
                <Form.Item>
                  <DiaryAdd>
                  </DiaryAdd>

                </Form.Item>
              </div>
              <Divider />
              <div className='form'>
                <ImgUploader placeholdText="添加封面图" ref={this.imgUploadRef}></ImgUploader>
                <Form
                  ref={this.formRef}
                  onFinish={() => this.onFinish()}
                  onFinishFailed={() => this.checkCover()}  //只要点击提交按钮，就要同时检查文本输入和封面上传框
                >
                  <Form.Item
                    name="name"
                    label="专辑名称："
                    rules={[
                      {
                        required: true,
                        message: "请输入专辑名称"
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="description"
                    label="专辑介绍："
                    rules={[
                      {
                        required: true,
                        message: '请输入专辑介绍'
                      }
                    ]}
                  >
                    <TextArea rows={5} />
                  </Form.Item>
                  <Form.Item name="action">
                    <Row>
                      <Col span={2} offset={20}>
                        <Space>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.submitButtonLoading}
                          >
                            发布
                      </Button>
                          <Button
                            htmlType="button"
                            onClick={() => this.onReset()}
                          >
                            取消
                      </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Form.Item>
                </Form>
              </div>
            </div>

          </TabPane>

        </Tabs>

      </Layout>
    );
  };


}

export default GalleryPubPage;
