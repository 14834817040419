
// the appId for viva test database configuration
export const appId='kYtddmLAUObYAasnXs9Dyyom-MdYXbMMI';

// the appkey for viva test database configuration
export const appKey='E2G4Qw9UYDcKLgv5wKIDFVaj';

export const masterKey="";


// Report database
export const Report="Report";

//Messages database
export const Messages="Messages";

//_User database
export const _User="_User";

//User_Data database
export const User_Data="User_Data";

//for report pool setting
//It is the content for the message that will be sent
//after the reported message was offshelf
//author Mingyang
export const offShelf_Msg_title= "reported title";
export const offShelf_Msg_content="reported content";
export const offShelf_Msg_rcvgroup=1;
export const offShelf_Msg_All_date=true;



//the appId for Mingyang's test database configuration
//todo delete the test database after the project is done
//author Mingyang
// export const appId='SeuzYfbkNShpaupvB2E0RggT-MdYXbMMI';
// export cosnt appKey='9dDQE3QXhmNkcNqXXmHeppQT';

