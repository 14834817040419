import { message } from 'antd';
import AV from 'leancloud-storage';

export async function queryReportedRecipients() {
  console.log('called queryReportedRecipients')
  var recipients;
  const query = new AV.Query('Report');
  query.include('reported');
  await query.find().then(allReports => {
    console.log(allReports.map(report => report.get('reported').get('username')));
    const reportedUsernames = allReports.map(report => report.get('reported').get('username'));
    console.log(reportedUsernames)
    recipients = reportedUsernames.join(';');
  }, error => message.error(error, 6));
  console.log('recipients', recipients);
  return recipients;
}

export async function queryReporterRecipients() {
  // 如果之后需要更多分组的话 可以把这两个函数封装一下
  console.log('called queryReporterRecipients')
  var recipients;
  const query = new AV.Query('Report');
  query.include('reporter');
  await query.find().then(allReports => {
    console.log(allReports.map(report => report.get('reporter').get('username')));
    const reporterUsernames = allReports.map(report => report.get('reporter').get('username'));
    console.log(reporterUsernames)
    recipients = reporterUsernames.join(';');
  }, error => message.error(error, 6));
  console.log('recipients', recipients);
  return recipients;
}
