//author: Mingyang SUN

import React, {Component} from 'react';
import { Checkbox } from 'antd';
import { Input,Table } from 'antd';
import {withRouter} from "react-router-dom";
import "./../css/user_management.css";
import {retrieveUserStatus, userList, violationsList} from "../services/user_management_service";
import {_User, Report,User_Data} from "../setting";


const AV = require('leancloud-storage/live-query');


const { Search } = Input;


class UserManagement extends Component {
    constructor(){
        super();
        this.state={
            data:[],
            wholeList:[],
            admin:[],
        }
        // this.onChange=this.onChange.bind(this)
    }

    componentDidMount() {
        this.getData();
    }

    getData= async ()=>{
        //retrieve data from database
        const User=new AV.Query(_User);

        User.find()
            .then((res)=>{
                //retrieving user data without updating the violations number
                const list=userList(res);
                //sync the data
                if (list.length===res.length){

                    //update the violations number
                    const report=new AV.Query(Report);
                    report.find()
                        .then((res)=>{

                            //get the id that violated, each id means violated once
                            const violatedList=violationsList(res);
                            //calculate the violated number
                            for (let k=0;k<list.length;k++){
                                for (let i=0;i<violatedList.length;i++){
                                    if (list[k]["id"].indexOf(violatedList[i])!==-1){
                                        list[k]["violations"]++;
                                    }
                                }
                            }
                            return list
                        }).then((list)=>{
                            const status=new AV.Query(User_Data);
                            status.find()
                                .then((res)=>{
                                    const finalList=retrieveUserStatus(res,list);
                                    this.setState({data:finalList,wholeList:finalList})
                                })
                                .catch((e)=>{
                                    console.log("Something wrong in connecting to User_Data database"+e)
                                })
                    }).catch((e)=>{
                            console.log("Something wrong in connecting to report database"+e)
                        });
                }
            })
            .catch((e)=>{
                console.log("Something wrong in connecting to _User database"+e)
            })

    }

    //retrieving violated user
    violatedUser=(e)=>{
        const {wholeList}=this.state;

        if (e.target.checked===true){
            this.filterViolatedUser();
        }
        else if (e.target.checked===false){
            this.setState({data:wholeList})
        }
    }

    //filter violated user
    filterViolatedUser=()=>{
        const {wholeList}=this.state;
        const violatedList=[];
        for (let i=0;i<wholeList.length;i++){
            if (wholeList[i]["status"]===true){
                violatedList.push(wholeList[i])
            }
        }
        this.setState({data:violatedList})
    }

    //retrieving normal user
    normalUser=(e)=>{
        const {wholeList}=this.state;

        if (e.target.checked===true){
            this.filterNormalUser();
        }
        else if (e.target.checked===false){
            this.setState({data:wholeList})
        }

    }

    //filter normal user
    filterNormalUser=()=>{
        const {wholeList}=this.state;
        const normalList=[];
        for (let i=0;i<wholeList.length;i++){
            if (wholeList[i]["status"]===false){
                normalList.push(wholeList[i])
            }
        }
        this.setState({data:normalList})
    }

    //search bar
    onSearch = (e) =>{
        this.filterData(e);
    }

    //filter input from search bar
    filterData=(keyword)=>{
        const {wholeList}=this.state;
        const searchList=[];
        const userNumber=wholeList.map(obj => obj.username).indexOf(keyword);
        const idNumber=wholeList.map(obj => obj.id).indexOf(keyword);
        if (userNumber!==-1){
            searchList.push(wholeList[userNumber])
        }
        if (idNumber!==-1){
            searchList.push(wholeList[idNumber])
        }
        this.setState({data:searchList})
    }

    renderStatus=(text,record)=>(
        <div>
            {
                record.status === true
                    ? <div className="user-man-ban-btn" onClick={this.banBtn.bind(this,record)}>封禁</div>
                    : record.status === false
                    ? <div className="user-man-normal-btn" onClick={this.unBanBtn.bind(this,record)}>解封</div>
                    : ""
            }
        </div>
    )

    banBtn=(record)=>{
        const userData = AV.Object.createWithoutData(User_Data, record.id);
        userData.set({'status':0});
        userData.save().then(()=>{
            this.componentDidMount()

        }).catch((e)=>{
            console.log("Errors in setting user status"+e)
        })
    }

    unBanBtn=(record)=>{
        const userData = AV.Object.createWithoutData(User_Data, record.id);
        userData.set({'status':1});
        userData.save().then(()=>{
            this.componentDidMount()
        }).catch((e)=>{
            console.log("Errors in setting user status"+e)
        })
    }

    // rowSelection objects indicates the need for row selection
    //  for further development
    // do not need this function so far
    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    };


    render() {
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width:"2%",
            },
            {
                title: '用户名',
                dataIndex: 'username',
                key: 'username',
                // width: '30%',
            },
            {
                title: '注册邮箱',
                dataIndex: 'email',
                key: 'email',
                // width: '20%',
            },
            {
                title: '注册时间',
                dataIndex: 'registered-date',
                key: 'address',
            },
            {
                title: '最后活跃',
                dataIndex: 'last-active',
                key: 'age',
                // width: '20%',
            },
            {
                title: '违规次数',
                dataIndex: 'violations',
                key: 'violations',
            },
            {
                title: '操作',
                dataIndex: 'status',
                key: 'status',
                render:this.renderStatus,
            },
        ];

        //configure pagination
        const paginationProps = {
            showQuickJumper: true,
            responsive:true,
            defaultPageSize:10,
            hideOnSinglePage:false,
            className:"user-man-pagination",
        };
        return (
            <div className="user-man-container">
                <div className="user-man-top">
                    <div className="user-man-search">
                        <Search placeholder="输入ID或用户名查询" onSearch={this.onSearch} allowClear/>
                    </div>
                    <div className="user-man-checkboxes">
                        <div className="user-man-checkboxes-normal">
                            <p>正常用户: </p><Checkbox onChange={this.normalUser}/>
                        </div>
                        <div className="user-man-checkboxes-violation">
                            <p>违规用户: </p><Checkbox onChange={this.violatedUser}/>
                        </div>
                    </div>
                </div>
                    <div>
                        <Table columns={columns} dataSource={this.state.data} pagination={paginationProps} rowSelection={ this.rowSelection }/>
                    </div>



            </div>
        );
    }
}

export default withRouter(UserManagement);
