//author: Mingyang SUN , oct 10 2020
import React, {Component} from 'react';
import { Table } from 'antd';
import { withRouter } from "react-router-dom";
import "../css/report_pool.css";
import {processReportPool} from "../services/report_pool_service";
import {Report,_User} from "../setting";
import "./../css/history_processing.css";
const AV = require('leancloud-storage/live-query');
const { Column } = Table;

class HistoryProcessingPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            visible: false,
            data:"",
            loadArray:[],
            reportedLength:0,
            reporterLength:0,
            pageNumber:this.props.location.state==="undefined"?1:this.props.location.state
        };
        this.click=this.click.bind(this);
        this.onPageChange=this.onPageChange.bind(this)
    }

    componentDidMount() {
        this.getData();
    }

    getData=async ()=>{

        //retrieve data from database
        const report=new AV.Query(Report);

        report.ascending('createdAt');


        await report.find().then((messages)=> {
            //get the length for sync data
            this.setState({length:messages.length});

            //retrieve data directly
            const sortedArray =processReportPool(messages,this.props.match.path);

            //retrieve data needs processing
            const query=new AV.Query(_User);

            //push reported username
            for(let i=0;i<messages.length;i++){
                if (messages[i]._serverData.reported!==undefined){
                    query.get(messages[i]._serverData.reported.id).then((msg)=>{
                        sortedArray[i]["reportedName"]=msg._serverData.username;
                        sortedArray[i]["reportedId"]=messages[i]._serverData.reported.id;
                        this.setState({reportedLength:this.state.reportedLength+1})
                    }).catch((e)=>{
                        console.log(e)
                    })
                } else{
                    sortedArray[i]["reportedName"]="No User Name";
                    sortedArray[i]["reportedId"]=null;

                    this.setState({reportedLength:this.state.reportedLength+1})
                }

                //push reporter username
                if(messages[i]._serverData.reporter!==undefined){
                    query.get(messages[i]._serverData.reporter.id).then((msg)=>{
                        sortedArray[i]["reporterName"]=msg._serverData.username;
                        this.setState({reporterLength:this.state.reporterLength+1})
                    }).catch((e)=>{
                        console.log("something wrong in getting reporter name"+e)
                    })
                } else {
                    sortedArray[i]["reporterName"]="No User Name";
                    this.setState({reporterLength:this.state.reporterLength+1})
                }
                //renew the data state
                this.setState({data:sortedArray})
            }
        })
            .catch((e)=>{
                console.log("something wrong in getting report data"+e)
            })
    }

    // link to detail page
    click=(columnInfo)=> {
        const {pageNumber,data}=this.state;
        columnInfo["pageNumber"]=pageNumber;
        columnInfo["list"]=data;
        this.props.history.push(
            {pathname:"/historyprocessing/"+columnInfo.id,
                state:columnInfo});
    };

    //get the pagination number
    onPageChange(page) {
        this.setState({pageNumber:page})
    }

    render() {
        const {
            data,
            length,
            reportedLength,
            reporterLength,
            pageNumber
        }=this.state;

        //configure pagination
        const paginationProps = {
            showQuickJumper: true,
            responsive:true,
            defaultPageSize:10,
            hideOnSinglePage:true,
            className:"report-pagination",
            current:pageNumber,
            onChange: (current) => this.onPageChange(current),
        };

        return (
            <div className="report_container">
                {reporterLength===length&&reportedLength===length?(
                    <Table dataSource={data} pagination={paginationProps}
                           onRow={
                               (record)=>{
                                   return{
                                       onClick:this.click.bind(this,record)
                                   }}
                           }
                    >
                        <Column title="序号" dataIndex="number" key="number" />
                        <Column title="内容关联地址" dataIndex="link" key="link" />
                        <Column title="理由" dataIndex="reason" key="reason" />
                        <Column title="内容形式" dataIndex="category" key="category" />
                        <Column title="举报时间" dataIndex="updatedAt" key="updatedAt"/>
                        <Column title="举报者" key="reporterName" dataIndex="reporterName"/>
                        <Column title="被举报者" key="reportedName" dataIndex="reportedName"/>
                        <Column title="状态" key="status" dataIndex="status"
                                render={(e)=>{
                                    if (e===1){
                                        return "未处理"
                                    }else if (e===2){
                                        return "下架"
                                    } else {
                                        return "驳回"
                                    }}}/>
                    </Table>
                ):"loading"}
            </div>
        );
    }
}

export default withRouter(HistoryProcessingPage);
