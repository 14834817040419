import React from 'react';
import { Modal, Button, Table, Space, message, Popconfirm } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons'
import arrayMove from 'array-move'
import AV from 'leancloud-storage';
import { processMuseums, unlistMuseum, saveSort } from '../services/museum_service';

import '../css/gallery_manage.css';
import GalleryPubPage from "./gallery_pub";


const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor:'pointer', color: '#999' }} />
));
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);


class GalleryManagePage extends React.Component {
  TABLE_COLUMNS = [   
    {                 
      title: '名称',
      dataIndex: 'name'
    },
    {
      title: '地址',
      dataIndex: 'url',
      render: text => <a href={text}>{text}</a>
    },
    {
      title: '内容数',
      dataIndex: 'items'
    },
    {
      title: '浏览数',
      dataIndex: 'views'
    },
    {
      title: '排序',
      dataIndex: 'sort',
      className: 'drag-visible',
      render: () => <DragHandle />
    },
      {
        title: '操作',
        dataIndex: 'action',
          render: (text, record, index) => {
            return (
            <Space size="small">
              <Button size="small"  onClick={this.showModal}>
                   编辑
                </Button>

              <Popconfirm title='话题下架后，该话题下全部内容将被删除' 
                okText='确认' 
                cancelText='取消' 
                onConfirm={() => this.onUnlist(index)}
               >
            <Button size="small" danger loading={this.state.unlistButtonLoading[index]}>
                   下架
                </Button>
                </Popconfirm>
            </Space>                      
            );
        },
      },
  ];

  state = {
    tableData: [],
    loaded: false,
    submitButtonLoading: false,
    saveSortButtonLoading: false,
    unlistButtonLoading: []

  };
  
  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { tableData } = this.state;
    const index = tableData.findIndex(x => x.index===restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />
  };

  async getData() {
    const museumQuery = new AV.Query('Museum');
    museumQuery.ascending('index');
    await museumQuery.find().then(museums => {
      const processedMuseums = processMuseums(museums, 'https://vivatoday.org/museums/?id=');  // Link is just for demonstration
      this.setState({
        tableData: processedMuseums,
        loaded: true
      });
    }, error => {
      message.error('加载时出现错误：'+error, 6);  // 延长了显示时间以便阅读错误信息
    });
  }

  componentDidMount() {
    // 获取表格数据
    this.getData();
  }

  onSortEnd(oldIndex, newIndex) {
    const { tableData } = this.state;

    if (oldIndex !== newIndex) {
      const newData = this.updateIndex(
        arrayMove([].concat(tableData), oldIndex, newIndex).filter(el => !!el)
      );  // 嵌套避免创建多余变量
      console.log('Sorted items: ', newData);
      this.setState({ tableData: newData });
    }
  }

  onSaveSort() {
    this.setState({ saveSortButtonLoading: true });
    const { tableData } = this.state;
    saveSort(tableData).then((newTableData) => {
      this.setState({ 
        saveSortButtonLoading: false,
        tableData: newTableData
      });
      message.success('保存成功');
    }, error => {
      this.setState({ saveSortButtonLoading: false });
      message.error('保存时出现错误：'+error, 6);
    });
  }


  async onUnlist(index) {
    const { tableData, unlistButtonLoading } = this.state;

    const modifyUBLoading = unlistButtonLoading.slice();
    modifyUBLoading[index] = true;
    this.setState({ unlistButtonLoading: modifyUBLoading });

    const targetMuseumId = tableData[index]['objectId'];
    await unlistMuseum(targetMuseumId).then(() => {
      window.location.reload();
    }, error => {
      message.error('下架时出现错误：'+error, 6);
      modifyUBLoading[index] = false;
      this.setState({ unlistButtonLoading: modifyUBLoading });
    });
  }
  

  updateIndex(tableData) {
    // 按顺序更新tableData中每项的index值
    var variableData = tableData.slice();
    for (let i = 0; i < variableData.length; i++){
      variableData[i].index = i+1;
    }
    return variableData;
  }

  valueOfField(fieldName) {  // Syntax sugar
    return this.formRef.current.getFieldValue(fieldName);
  }

// 编辑的弹窗显示
  showModal = () => {
    this.setState({
        visible: true,
    });
};

// 编辑的取消
handleCancel = () => {
    console.log('Clicked cancel button');
    this.setState({
      visible: false,
    });
  };


// link to detail page
click=(record)=> {
  this.setState({
      visible: true
  });
  // this.props.history.push('/gallery_manage/'+record.key)
};

  render() {
    const { tableData } = this.state;
    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass='row-dragging'
        onSortEnd={({ oldIndex, newIndex }) => this.onSortEnd(oldIndex, newIndex)}
        {...props}
      />
    );

    return (
      <div className='container'>
        <div className="table">
          <Space className="table-title" align="baseline">   
            <div className='table-title-text'>当前发布艺术专辑</div>
            <Button 
              type="primary" 
              onClick={() => this.onSaveSort()}
              loading={this.state.saveSortButtonLoading}
              >保存
            </Button>
          </Space>
          <Table
            pagination={false}
            columns={this.TABLE_COLUMNS}
            dataSource={tableData}
            rowKey="index"
            className='table'
            loading={!this.state.loaded}
            scroll={{
              y:true
            }}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: this.DraggableBodyRow,
              },
            }}
          />
        </div>
        <Modal
                    visible={this.state.visible}
                    //onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[]}
                    width={"60%"}
                    height={"500px"}
                    style={{ top:"20px"}}
                >
                    <GalleryPubPage />

                    {/* <p>这里是弹窗的内容</p> */}
                </Modal>
      </div>
    );
  }
}

export default GalleryManagePage;
