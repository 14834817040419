//Author Mingyang (07 oct.2020)

import React from 'react';
import "./../css/sidebar.css";
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

import {
    HomeFilled
} from '@ant-design/icons';
import "antd/dist/antd.css";
const { SubMenu } = Menu;

class SideBar extends React.Component {
    state = {
        collapsed: false,
    };

    checkRouter=()=>{
        const {pathname}=this.props.history.location;
        const arr= pathname.split("/");
        let menuName=["overview","topic","challenge","banner","gallery_pub",
            "gallery_manage","usermanagement","reportpool","historyprocessing","message/new","message/history"];
        const number=[];
        number[0]=(menuName.indexOf(arr[1])+1).toString();
        return number
    }

  render() {
    return (
        <div className="sidebar_container">
            <Menu
                defaultSelectedKeys={['1']}
                mode="inline"
                // theme="dark"
                className="sidebar"
                inlineCollapsed={this.state.collapsed}
                selectedKeys={this.checkRouter()}
            >
                <Menu.Item key="1" icon={<HomeFilled />}>
                    <Link to="/overview" className="sidebar_overview">概述</Link>
                </Menu.Item>
                <SubMenu key="sub1" icon={<HomeFilled />} title="内容发布">
                    <Menu.Item key="2"><Link to="/topic">话题发布&管理</Link></Menu.Item>
                    <Menu.Item key="3"><Link to="/challenge">挑战发布&管理</Link></Menu.Item>
                    <Menu.Item key="4"><Link to="/banner">banner发布&管理</Link></Menu.Item>
                    <Menu.Item key="5"><Link to="/gallery_pub">艺术馆发布</Link></Menu.Item>
                    <Menu.Item key="6"><Link to="/gallery_manage">艺术馆管理</Link></Menu.Item>
                </SubMenu>
                <Menu.Item key="7" icon={<HomeFilled />}>
                    <Link className="sidebar_overview" to={"/usermanagement"}>用户管理</Link>
                </Menu.Item>
                <SubMenu key="sub2" icon={<HomeFilled />} title="审核管理">
                    <Menu.Item key="8"><Link to="/reportpool">举报池</Link></Menu.Item>
                    <Menu.Item key="9"><Link to="/historyprocessing">历史处理</Link></Menu.Item>
                </SubMenu>
                <SubMenu key="sub3" icon={<HomeFilled />} title="消息群发">
                    <Menu.Item key="10"><Link to="/message/new">新建群发</Link></Menu.Item>
                    <Menu.Item key="11"><Link to="/message/history">历史群发</Link></Menu.Item>
                </SubMenu>
            </Menu>
        </div>

    );
  }
}

export default SideBar;
