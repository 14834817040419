import AV from "leancloud-storage";

export async function getData() {
  const messageQuery = new AV.Query('Messages');
  const rawMessages = await messageQuery.find();  // 获取所有群发消息
  console.log('rawMessages', rawMessages);
  const processedMessages = [];
  for (let i=0; i<rawMessages.length; i++) {
    processedMessages.push(rawMessages[i]['attributes']);
    processedMessages[i]['objectId'] = rawMessages[i]['id'];
  }
  return processedMessages;
}

export async function getMessageDetails(id) {
  const messageQuery = new AV.Query('Messages');
  const msg = await messageQuery.get(id);
  const processedMsg = Object.assign({
    objectId: id,
  }, msg['attributes']);
  return processedMsg;
}
