import AV from 'leancloud-storage';


export function processMuseums(museums, baseUrl) {
  var processedMuseums = [];
  for (let i=0;i<museums.length;i++) {
    processedMuseums.push(museums[i]['attributes']);
    processedMuseums[i]['url'] = baseUrl + museums[i]['id'];
    processedMuseums[i]['objectId'] = museums[i]['id'];
    processedMuseums[i]['items'] = 0;
    processedMuseums[i]['views'] = 0;
    processedMuseums[i]['initialIndex'] = museums[i]['index'];
  }
  return processedMuseums;
}

export function saveSort(tableData, ) {
  return new Promise((resolve, reject) => {
    let initIndexSortedData = tableData.slice();
    initIndexSortedData.sort((a, b) => a['initialIndex'] - b['initialIndex']);  // 获取最初最小index
    let sortedData = tableData.slice();
    // console.log('initialData', initialData);
    sortedData.sort((a, b) => a['index'] - b['index']);  // 用于按顺序更新index
    console.log('dataCopy', sortedData);

    let indexDelta = 0;  // 平移距离
    if (initIndexSortedData[0]['initialIndex'] < sortedData.length) {
      indexDelta = sortedData.length;  // 平移到N+1开始
    } else if (initIndexSortedData[0]['initialIndex'] > sortedData.length) {
      // updateIndex已经自动从1分配了index
    }
    console.log('indexDelta:', indexDelta);

    for (var i=0; i<sortedData.length; i++) {
      const museumObject = AV.Object.createWithoutData('Museum', sortedData[i]['objectId']);  // 获取专辑
      const newIndex = sortedData[i]['index'] + indexDelta;
      museumObject.set('index', newIndex);  // 更新该专辑index
      sortedData[i]['initialIndex'] = newIndex;  // 更新本地专辑index
      console.log('museumObject index', sortedData[i]['index'], '=>', sortedData[i]['index'] + indexDelta);
      if (museumObject.dirtyKeys()) {  // 如果index与更新前不同，
        museumObject.save().then(() => {  // 保存
          if (i === sortedData.length) {
            resolve(sortedData);
          }
          console.log('SaveSort', i, '/', sortedData.length)  // Eg, SaveSort 1/8
        }, error => {
          {reject(error);return;};
        });
      } else if (i === sortedData.length) {
        resolve(sortedData);
      }
    }
  });
}

export async function unlistMuseum(objectId) {
  // 以下注释是为了便于阅读嵌套Promise而写的，对程序没有作用
  // 但更新Promise逻辑后还是重写一下注释，方便之后理解
  return new Promise((resolve, reject) => {
    const museumQuery = new AV.Query('Museum');
    museumQuery.get(objectId)
    .then(museumToBeUnlisted => {  // [Start_0] 查询专辑
      const creationQuery = new AV.Query('Creation');
      creationQuery.equalTo('museum', museumToBeUnlisted);
      creationQuery.find().then(creations => {   // [Start_1] 查询子创作
        console.log('Creations found:', creations);

        creations.forEach((currentCreation, i) => {
          const commentQuery = new AV.Query('Comment');
          commentQuery.equalTo('targetCreation', currentCreation);
          commentQuery.find().then(comments => {// [Start_2] 查询子评论
            console.log('Found comments:', comments);
  
            AV.Object.destroyAll(comments).then(() => { // [Start_3] 删除子评论
              console.log('Comments deleted for', i);
              if (i+1 === creations.length) {  // 所有子创作处理完毕
                AV.Object.destroyAll(creations).then(() => {  // [Start_4] 删除所有子创作
                  console.log('Creations all deleted')
                  museumToBeUnlisted.destroy().then(           // [Start_5] 删除专辑
                    () => {
                      console.log('Museum deleted');
                      resolve();
                    }, error => {reject(error);return;}                 // [End_5] 删除专辑
                  );
                }, error => {reject(error);return;});                 // [End_4] 删除所有子创作
              }
            }, error => {reject(error);return;});                 // [End_3] 删除子评论
            
            if ((comments.length === 0) && (i+1 === creations.length)) {  // 如果没有相关评论且创作处理完毕，
              console.log('No comments found for', i);
              AV.Object.destroyAll(creations).then(() => {  // [Start_6] 直接删除子创作
                museumToBeUnlisted.destroy().then(           // [Start_7] 删除专辑
                  () => {
                    console.log('Museum deleted');
                    resolve();
                  }, error => {reject(error);return;}                 // [End_7] 删除专辑
                );
              }, error => {reject(error);return;});                 // [End_6] 直接删除子创作
            }
          }, error => {reject(error);return;});          // [End_2] 查询子评论
        });
  
        if (creations.length === 0) {// 如果没有相关创作，直接删除专辑
          console.log('No creations found');
          museumToBeUnlisted.destroy();
          console.log('Museum deleted');
          resolve();
        }
      }, error => {reject(error);return;});           // [End_1] 查询子创作
    }, error => {reject(error);return;});   // [End_0] 查询专辑
    
  }); // return语句结束
}
