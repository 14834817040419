import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, Radio, Space, Popconfirm, Table, Tag, message } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import AV from 'leancloud-storage';

import ImgUploader from '../components/img_uploader';

import "./../css/banner.css";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));

const POSITION_CHECKLIST = ['广场&我的关注', '官方主页'];
const DEFAULT_POSITION_CHECKLIST = [];
const CAROUSEL_OPTIONS = [
  {
    label: "第一张",
    value: 1
  },
  {
    label: "第二张",
    value: 2
  },
  {
    label: "第三张",
    value: 3
  },
];

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

class BannerPage extends Component {
  formRef = React.createRef();  // 用于清空表格
  imgUploadRef = React.createRef();  // 用于检查封面选择
  TABLE_COLUMNS = [
    {
      title: '位置',
      dataIndex: 'position',
      key: 'name',
      render: (position, record) => (
        <>
          {position.map(tag => {
            let color = tag > 0 ? 'geekblue' : 'green';
            return (
              <Tag color={color} key={tag}>
                {POSITION_CHECKLIST[tag]}
              </Tag>
            );
          })}
        </>
      )
    },
    {
      title: '海报图',
      dataIndex: 'image',
      render: url => <a href={url} target="_blank"><img style={{ width: 50 }} src={url} /></a>
    },
    {
      title: '顺序',
      dataIndex: 'carousel',
    },
    {
      title: '名称',
      dataIndex: 'name',
      className: 'drag-visible',
    },
    {
      title: '跳转链接',
      dataIndex: 'link',
      render: text => <a href={text}>{text}</a>,
    },
    {
      title: '点击次数',
      dataIndex: 'count',
    },
    // TODO: 排序功能暂不可用，需要解决两个位置混杂排序的情况
    // {
    //   title: '排序',
    //   dataIndex: 'sort',
    //   className: 'drag-visible',
    //   render: () => <DragHandle />,
    // },
    // {
    //   title: 'index',
    //   dataIndex: 'index'
    // },
    {
      title: '操作',
      dataIndex: 'action',
      render: (text, record) => (
        <Popconfirm title="下架后，该 banner 将被删除" okText="确认" onConfirm={() => { this.onDeleteBanner(record) }} cancelText="取消">
          <Button size="small" danger>
            下架
          </Button>
        </Popconfirm>
      ),
    },
  ];

  state = {
    checkedList: DEFAULT_POSITION_CHECKLIST,
    indeterminate: false,
    checkAll: false,
    tableData: [],
    publishButtonLoading: false,
    saveSortButtonLoading: false,
  }

  /**
   * 请求 banner 数据
   */
  getAllBannerData = async () => {
    const queryBanner = new AV.Query('Banner');
    const resBanner = await queryBanner.ascending('index').find();
    const processedResBanner = resBanner.map((item, index) => ({
      key: item.id,
      position: [0],
      image: item.attributes.image,
      carousel: item.attributes.index,
      name: item.attributes.name,
      link: item.attributes.link,
      count: 0,
      index 
    }));

    const queryOfficialBanner = new AV.Query('OfficialBanner');
    const resOfficialBanner = await queryOfficialBanner.ascending('index').find();
    const processedResOfficialBanner = resOfficialBanner.map((item, index) => ({
      key: item.id,
      position: [1],
      image: item.attributes.image,
      carousel: item.attributes.index,
      name: item.attributes.name,
      link: item.attributes.link,
      count: 0,
      index: index+processedResBanner.length
    }));
    const processedRes = processedResBanner.concat(processedResOfficialBanner);
    return processedRes;
  }

  /**
   * 刷新 banner 数据
   */
  async refreshData() {
    const bannerRes = await this.getAllBannerData();
    // 获取表格数据
    this.setState({
      tableData: bannerRes
    })
  }

  componentDidMount() {
    this.refreshData();
  }

  /**
   * 完成拖拽
   * @param {*} param0 
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { tableData } = this.state;
    if (oldIndex !== newIndex) {
      const newData = arrayMove([].concat(tableData), oldIndex, newIndex).filter(el => !!el);
      this.setState({ tableData: newData });
    }
  };

  /**
   * 拖拽后点击保存排序
   */
  onSaveSort = async () => {
    this.setState({
      saveSortButtonLoading: true,
    })
    const { tableData } = this.state;
    let reqList = [];
    tableData.forEach((item, index) => {
      reqList.push(AV.Object.createWithoutData('Banner', item.key).set('index', index).save())
    })
    const sortRes = await Promise.all(reqList);
    if (sortRes) {
      message.success('排序保存成功');
      this.setState({
        saveSortButtonLoading: false,
      })
      await this.refreshData();
    }
  }

  /**
   * 拖拽某一行
   * @param {*} param0 
   */
  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { tableData } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = tableData.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  /**
   * 删除某条 banner
   * @param {*} record 
   */
  onDeleteBanner = async (record) => {
    const deleteRes = await AV.Object.createWithoutData('Banner', record.key).destroy();
    if (deleteRes) {
      message.success('删除成功');
      await this.refreshData();
    }
  }

  /**
   * 勾选展示位置
   * @param {*} checkedList 
   */
  onPositionCheckChange = checkedList => {
    this.setState({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < POSITION_CHECKLIST.length,
      checkAll: checkedList.length === POSITION_CHECKLIST.length,
    });
  };

  /**
   * 点击全选
   * @param {*} e 
   */
  onCheckAllChange = e => {
    this.setState({
      checkedList: e.target.checked ? POSITION_CHECKLIST : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  resetForm = () => {
    this.formRef.current.resetFields();
    this.setState({
      checkedList: DEFAULT_POSITION_CHECKLIST,
      indeterminate: false,
      checkAll: false,
    })
  }

  /**
   * 添加 banner 
   * @param {*} formData 
   */
  finishForm = async formData => {
    if (this.checkPosition() && this.checkCover()) {
      this.setState({
        publishButtonLoading: true,
      })

      // 先将勾选的展示位置转成 number[]
      const position = this.state.checkedList.map(item => {
        return POSITION_CHECKLIST.indexOf(item);
      })

      // 上传图片
      const { imageData, imageName } = this.imgUploadRef.current.state;
      const imgFile = new AV.File(imageName, { base64: imageData });
      const fileRes = await imgFile.save();
      
      // 图片保存成功后保存 banner
      if (fileRes) {
        for (let i = 0; i < position.length; i++) {   // 可以同时向多个位置发banner
          let item = position[i];
          // 查找对应位置有没有banner
          let exist = -1;
          for (let j = 0; j < this.state.tableData.length; j++) {
            let t = this.state.tableData[j];
            if (t.position[0] === item && t.carousel === formData.carousel) {
              exist = j;
              break;
            }
          }

          let newObj;
          if (exist === -1) {
            // 没找到，添加
            if (item === 0) {   // '广场&我的关注'
              newObj = AV.Object('Banner');
            } else if (item === 1) {    // '官方主页'
              newObj = AV.Object('OfficialBanner');
            }
          } else {
            // 找到，更新
            if (item === 0) {   // '广场&我的关注'
              newObj = AV.Object.createWithoutData('Banner', this.state.tableData[exist].key);
            } else if (item === 1) {    // '官方主页'
            newObj = AV.Object.createWithoutData('OfficialBanner', this.state.tableData[exist].key);
            }
          }
          
          newObj.set({ 
            image: fileRes.attributes.url, 
            index: formData.carousel,
            link: formData.link,
            name: formData.name 
          });
          try {
            await newObj.save();
          } catch (e) {
            message.error('保存出错');
            console.log(e);
          }
        }
        
        message.success('保存成功');
        this.setState({
          publishButtonLoading: false,
        });
        this.resetForm();
        await this.refreshData();
      }
    }
  }
  
  /**
   * 检查是否勾选了展示位置
   */
  checkPosition() {
    if (this.state.checkedList.length > 0) {
      return true;
    } else { 
      message.warn('请勾选展示位置');
      return false;
    }
  }

  /**
   * 检查是否选择了封面
   */
  checkCover() {
    if (this.imgUploadRef.current.hasChosenImage()) {
      return true;
    } else {
      message.warn('请选择封面图');
      return false;
    }
  }

  render() {
    const { tableData } = this.state;
    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass="row-dragging"
        onSortEnd={this.onSortEnd}
        {...props}
      />
    );
    return (
      <div className="banner_container">
        <div className="banner_form">
          <ImgUploader placeholdText="添加海报" ref={this.imgUploadRef} />
          <Form
            ref={this.formRef}
            onFinish={this.finishForm}
          >
            <Form.Item
              name="position"
              label="展示位置"
              required
            >
              <>
                <Checkbox.Group
                  options={POSITION_CHECKLIST}
                  value={this.state.checkedList}
                  onChange={this.onPositionCheckChange}
                />
                <Checkbox
                  indeterminate={this.state.indeterminate}
                  onChange={this.onCheckAllChange}
                  checked={this.state.checkAll}
                >
                  全选
                </Checkbox>
              </>
            </Form.Item>
            <Form.Item
              name="carousel"
              label="轮播位置"
              rules={[
                {
                  required: true,
                  message: '请选择轮播图位置'
                },
              ]}
            >
              <Radio.Group
                options={CAROUSEL_OPTIONS}
                value={this.state.checkedList}
              />
            </Form.Item>
            <Form.Item label="活动名称" required name="name">
              <Input />
            </Form.Item>
            <Form.Item label="跳转链接" name="link">
              <Input />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" loading={this.state.publishButtonLoading}>
                  发布
                </Button>
                <Button htmlType="button" onClick={this.resetForm}>
                  重置
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
        <div className="banner_table">
          <div className="banner_table_title">
            <div>当前发布 Banner </div>
            {/* <Button type="primary" onClick={this.onSaveSort} loading={this.state.saveSortButtonLoading}>保存</Button> */}
          </div>
          <Table
            pagination={false}
            columns={this.TABLE_COLUMNS}
            dataSource={tableData}
            rowKey="index"
            components={{
              body: {
                wrapper: DraggableContainer,
                row: this.DraggableBodyRow,
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default BannerPage;
