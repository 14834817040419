import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form, Input, Radio, DatePicker, Checkbox, Space, Button, Row, Col, message } from 'antd';
import AV from 'leancloud-storage';
import { queryReportedRecipients, queryReporterRecipients } from '../services/new_message_service';

import '../css/new_message.css'


const { RangePicker } = DatePicker;
const { TextArea } = Input;

const USER_GROUP_OPTIONS = [
    {
        label: '全体用户',
        value: 0
    },
    {
        label: '违规用户',
        value: 1
    },
    {
        label: '举报用户',
        value: 2
    },
    {
        label: '手动录入',
        value: 3
    }
];

class NewMessagePage extends React.Component {
    rangePickerRef = React.createRef();
    formRef = React.createRef();
    state = {
        timeChooserDisabled: false,
        manualInput: false,
        saveLoading: false,
    };

    onAllTimeChange() {
        const { timeChooserDisabled } = this.state;
        this.setState({timeChooserDisabled: !timeChooserDisabled});
    }

    onGroupChange(e) {
        this.setState({ manualInput: e.target.value === 3 });
    }

    onReset() {
        this.formRef.current.resetFields();
    }

    async onFinish(history) {
      try {
        this.setState({saveLoading: true});
        const values = await this.formRef.current.validateFields();
        console.log('form values', values);

        const newMessage = new AV.Object('Messages');
        newMessage.set('title', values.title);
        newMessage.set('content', values.content);
        if (this.state.timeChooserDisabled) {
          newMessage.set('all_date', 1);
        } else {
          newMessage.set('all_date', 0);
          const timeRanges = this.state.timeChooserDisabled? 0 : values.timeRange;
          const startDate = timeRanges[0].toDate();
          const endDate = timeRanges[1].toDate();
          newMessage.set('start_date', startDate);
          newMessage.set('end_date', endDate);
        }
        switch (values.userGroup) {
          case 0:
            newMessage.set('rcvgroup', 0);
            break;
          case 1:
            const reported = await queryReportedRecipients();
            newMessage.set('receiver', reported);
            newMessage.set('rcvgroup', 1);
            break;
          case 2:
            const reporters = await queryReporterRecipients();
            newMessage.set('receiver', reporters);
            newMessage.set('rcvgroup', 1);
            break;
          case 3:
            newMessage.set('receiver', values.recipients);
            newMessage.set('rcvgroup', 1);
            break;
          default:
            newMessage.set('rcvgroup', 1);
        }
        await newMessage.save().catch(error => message.error(error));
        message.success('发送成功');
        this.setState({saveLoading: false});
        history.push('/message/history');
      } catch (error) {
        console.log(error);
        this.setState({saveLoading: false});
        return;
      }
    }

    render() {
        const { timeChooserDisabled } = this.state;
        const SendButton = withRouter(({ history }) => (
            <Button
              htmlType="submit"
              type="primary"
              loading={this.state.saveLoading}
              onClick={() => {this.onFinish(history)}}>
                发送
            </Button>
        ));

        return (
            <div className="new-message-container">
                <Form ref={this.formRef}>
                    <div className="message-setting-form">
                        <Form.Item
                            name="recipients"
                            label="发送对象："
                            rules={[
                                {
                                    required: this.state.manualInput,
                                    message: '请输入发送对象'
                                }
                            ]}
                        >
                            <Input
                                placeholder='手动输入用户ID或注册邮箱，以“; ”隔开'
                                disabled={!this.state.manualInput}  // 如果不是手动输入，则关闭输入框
                            />
                        </Form.Item>
                        <Form.Item
                            name="userGroup"
                            label="编辑群组："
                            className="edit-group"
                            rules={[
                                {
                                    required: true,
                                    message: '请选择群组'
                                }
                            ]}
                        >
                            <Radio.Group
                                options={USER_GROUP_OPTIONS}
                                onChange={(e) => this.onGroupChange(e)}
                            />
                        </Form.Item>
                        <Space>
                            <Form.Item
                                name='timeRange'
                                label="统计时间段"
                                rules={[
                                    {
                                        required: !timeChooserDisabled,
                                        message: '请选择统计时间段'
                                    }
                                ]}
                            >
                                <RangePicker
                                    disabled={timeChooserDisabled}
                                    ref={this.rangePickerRef}
                                    className='time-range-selector'
                                />
                            </Form.Item>
                            <Form.Item
                                name='allRanges'
                            >
                                <Checkbox onChange={() => this.onAllTimeChange()}>全时间段</Checkbox>
                            </Form.Item>
                        </Space>
                    </div>
                    <div className="message-content-form">
                        <Form.Item
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入消息标题'
                                }
                            ]}
                        >
                            <Input placeholder='输入标题'/>
                        </Form.Item>
                        <Form.Item
                            name='content'
                            rules={[
                                {
                                    required: true,
                                    message: '请输入消息正文'
                                }
                            ]}
                        >
                            <TextArea placeholder='正文' rows={7}/>
                        </Form.Item>
                        <Form.Item name="action">
                            <Row><Col span={2} offset={21}>
                                <Space>
                                    <SendButton />
                                    <Button
                                        htmlType='button'
                                        onClick={() => this.onReset()}
                                    >
                                        取消
                                    </Button>
                                </Space>
                            </Col></Row>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        );
    }
}

export default NewMessagePage;
