//author: Mingyang SUN , oct 10 2020
import React, {Component} from 'react';
import "../css/report_pool_comment.css";
import { withRouter } from "react-router-dom";
const AV=require("leancloud-storage");

class ReportPoolComment extends Component {
    constructor(props){
        super(props);
        this.state={
            data:"",
            error:""
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData= async ()=>{
        const {data,contentId}=this.props;
        if (data===null || contentId===null){
            this.setState({error:"Error in getting content from database"})
        } else{
            const query = new AV.Query('Comment');
            await query.get(contentId)
            // await query.get("5fa4d62b47a50744284a1009")

                .then((msg)=>{
                    this.setState({data:msg},()=>{
                        // console.log(this.state.data)
                    })
                })
                .catch((e)=>{
                    console.log("something wrong in getting creation"+e)
                    this.setState({error:"Error in getting content from database"})
                })
        }
    }

    render() {
        //content from parent component
        const data= this.state.data["_serverData"];
        const {error}=this.state;
        return (
            <div>
                { data||"".length>0?(
                <div className="report_comment_container">
                    {data.content?data.content:"no content in here"}

                </div>
                ):error
                    ? error
                    :"loading"}
            </div>
        );
    }
}

export default withRouter(ReportPoolComment);
