//author Mingyang

import {timeFormat} from "../functions/time_format";
import {
    Messages,
    _User,
    offShelf_Msg_title,
    offShelf_Msg_content,
    offShelf_Msg_rcvgroup, offShelf_Msg_All_date
} from "../setting";

const AV=require("leancloud-storage");

//process the data from "Report" database
export const processReportPool=(messages,path)=>{

        //create a new array to store the data
        const sortedArray=[];

        //process the rest of the data
        for(let i=0;i<messages.length;i++){

            //push the current useful info
            sortedArray.push(messages[i]._serverData);

            //push date with correct format
            sortedArray[i]["updatedAt"]=timeFormat(messages[i]["updatedAt"]);

            //push the link
            //todo check whether the link is correct or not
            sortedArray[i]["link"]=path+"/"+messages[i]["id"];

            //push id
            sortedArray[i]["id"]=messages[i]["id"];

            //push number
            sortedArray[i]["number"]=i+1;

            //push content id
            sortedArray[i]["contentId"]=messages[i]["attributes"][messages[i]["attributes"]["category"]]
                ?
                messages[i]["attributes"][messages[i]["attributes"]["category"]]["id"]
                : null;
        }

        return sortedArray;
};


//get all the receivers' email
//send message to "Message" database after offshelf
export const send_msg_after_offshelf =(id)=>{
    const query=new AV.Query(_User);
    query.get(id)
        .then((detail) => {
            //retrieve the email from reported user
            return detail.attributes.email;
        })
        .then((email)=>{
            //update Message database
            const Msg=AV.Object.extend(Messages);
            const msg=new Msg();

            msg.set({
                receiver:email,
                title:offShelf_Msg_title,
                content:offShelf_Msg_content,
                rcvgroup:offShelf_Msg_rcvgroup,
                all_date:offShelf_Msg_All_date
            });

            msg.save().then(()=>{
                console.log("done")
            }).catch((e)=>{
                console.log("Errors in adding reported msg to Message database"+e)
            })

        })
        .catch((e)=>{
            console.log("Errors in retrieving email from _User database"+e);
        });
};
