/* 大范围复用自topic.js */
import React from 'react';
import ImgUploader from '../components/img_uploader';
import { Form, Input, Button, Table, Space, Row, Col, message, Popconfirm } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons'
import arrayMove from 'array-move'
import AV from 'leancloud-storage';
import { processChallenges, unlistChallenge, saveSort } from '../services/challenge_service';
import { create_data } from '../services/challenge_debug'

import '../css/challenge.css';


const { TextArea } = Input;
const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor:'pointer', color: '#999' }} />
));
const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);


class ChallengePage extends React.Component {
  formRef = React.createRef();  // 用于清空表格
  imgUploadRef = React.createRef();  // 用于检查封面选择
  TABLE_COLUMNS = [   // 因为“下架”的render需要和onUnlist交互，而onUnlist需要和ChallengePage的
    {                 // this.state.tableData交互，所以TABLE_COLUMNS需要放到这里来
      title: '名称',
      dataIndex: 'title'
    },
    {
      title: '地址',
      dataIndex: 'url',
      render: text => <a href={text}>{text}</a>
    },
    {
      title: '发布数',
      dataIndex: 'items'
    },
    {
      title: '浏览数',
      dataIndex: 'views'
    },
    {
      title: '排序',
      dataIndex: 'sort',
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: '操作',
      dataIndex: 'action',
      render: (text, record, index) => {
        return (
          <Popconfirm title='挑战下架后，该挑战下全部内容将被删除' 
            okText='确认' 
            cancelText='取消' 
            onConfirm={() => this.onUnlist(index)}
          >
            <Button size="small" danger loading={this.state.unlistButtonLoading[index]}>
              下架
            </Button>
          </Popconfirm>
        );
      }
    }
  ];

  state = {
    tableData: [],
    loaded: false,
    submitButtonLoading: false,
    saveSortButtonLoading: false,
    unlistButtonLoading: []
  };
  
  DraggableBodyRow = ({ className, style, ...restProps }) => {
    const { tableData } = this.state;
    const index = tableData.findIndex(x => x.index===restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />
  };

  async getData() {
    const challengeQuery = new AV.Query('Challenge');
    challengeQuery.ascending('index');
    await challengeQuery.find().then(challenges => {
      const processedChallenges = processChallenges(challenges, 'https://vivatoday.org/challenges/?id=');  // Link is just for demonstration
      this.setState({
        tableData: processedChallenges,
        loaded: true
      });
    }, error => {
      message.error('加载时出现错误：'+error, 6);  // 延长了显示时间以便阅读错误信息
    });
  }

  componentDidMount() {
    // 获取表格数据
    this.getData();
  }

  onSortEnd(oldIndex, newIndex) {
    const { tableData } = this.state;

    if (oldIndex !== newIndex) {
      const newData = this.updateIndex(
        arrayMove([].concat(tableData), oldIndex, newIndex).filter(el => !!el)
      );  // 嵌套避免创建多余变量
      console.log('Sorted items: ', newData);
      this.setState({ tableData: newData });
    }
  }

  onSaveSort() {
    this.setState({ saveSortButtonLoading: true });
    const { tableData } = this.state;
    saveSort(tableData).then((newTableData) => {
      this.setState({ 
        saveSortButtonLoading: false,
        tableData: newTableData
      });
      message.success('保存成功');
      // window.location.reload();
    }, error => {
      this.setState({ saveSortButtonLoading: false });
      message.error('保存时出现错误：'+error, 6);
    });
  }

  async onUnlist(index) {
    const { tableData, unlistButtonLoading } = this.state;

    const modifyUBLoading = unlistButtonLoading.slice();
    modifyUBLoading[index] = true;
    this.setState({ unlistButtonLoading: modifyUBLoading });

    const targetChallengeId = tableData[index]['objectId'];
    unlistChallenge(targetChallengeId).then(() => {
      console.log('Unlist completed');
      window.location.reload();
    }, error => {
      message.error('下架时出现错误：'+error, 6);
      modifyUBLoading[index] = false;
      this.setState({ unlistButtonLoading: modifyUBLoading });
    });
  }
  
  onReset() {
    this.formRef.current.resetFields();  // 清除所有输入的内容
  }

  onFinish() {
    if (this.checkCover()){  // 如果选择了封面
      this.setState({submitButtonLoading: true});

      const newChallenge = new AV.Object('Challenge');
      const newMessage = new AV.Object('Messages');
      const { imageData, imageName } = this.imgUploadRef.current.state;
      const imgFile = new AV.File(imageName, { base64: imageData });

      imgFile.save().then((file) => {
        newChallenge.set('title', this.valueOfField('title'));
        newChallenge.set('content', this.valueOfField('content'));
        newChallenge.set('picture', file);
        newChallenge.save().then(challenge => {
          newMessage.set('all_date', true);
          newMessage.set('content', "新挑战已经发布！");
          newMessage.set('rcvgroup', 0);
          newMessage.set('title', '邀您参加创意挑战 "'+this.valueOfField('title')+'"');  // Placeholder message
          newMessage.save().then(() => {
            this.setState({ submitButtonLoading: false });
            window.location.reload();  // 刷新页面
          }, error => {
            message.error('发送站内信时出现错误：'+error, 6);
          this.setState({ submitButtonLoading: false });
          });
        }, error => {
          message.error('发布时出现错误：'+error, 6);
          this.setState({ submitButtonLoading: false });
        });
      }, error => {
        message.error('上传图片时出现错误：'+error, 6);
        this.setState({ submitButtonLoading: false });
      });
    } else { return }  // 如果没选择封面，则不存储数据
  }

  checkCover(){
    // 检查是否选择了封面
    if (this.imgUploadRef.current.hasChosenImage()){
      return true;
    } else {
      message.warn('请选择封面图');
      return false;
    }
  }

  updateIndex(tableData) {
    // 按顺序更新tableData中每项的index值
    var variableData = tableData.slice();
    for (let i = 0; i < variableData.length; i++){
      variableData[i].index = i+1;
    }
    return variableData;
  }

  valueOfField(fieldName) {  // Syntax sugar
    return this.formRef.current.getFieldValue(fieldName);
  }
  
  render() {
    const { tableData } = this.state;
    const DraggableContainer = props => (
      <SortableContainer
        useDragHandle
        helperClass='row-dragging'
        onSortEnd={({ oldIndex, newIndex }) => this.onSortEnd(oldIndex, newIndex)}
        {...props}
      />
    );

    return (
      <div className='challenge-container'>
        <div className='form'>
          <ImgUploader placeholdText="添加封面图" ref={this.imgUploadRef}></ImgUploader>
          <Form 
            ref={this.formRef} 
            onFinish={() => this.onFinish()}
            onFinishFailed={() => this.checkCover()}  // 只要点击提交按钮，就要同时检查文本输入和封面上传框
          >
            <Form.Item
              name="title"
              label="挑战名称："
              rules={[
                {
                  required: true,
                  message: "请输入挑战名称"
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item 
              name="content"
              label="挑战简介：" 
              rules={[
              {
                required: true,
                message: '请输入挑战简介'
              }
              ]}
            >
              <TextArea rows={5}/>
            </Form.Item>
            <Form.Item name="action">
              <Row>
                <Col span={2} offset={20}>
                  <Space>
                    <Button 
                      type="primary" 
                      htmlType="submit"
                      loading={this.state.submitButtonLoading}
                    >
                      发布
                    </Button>
                    <Button 
                      htmlType="button" 
                      onClick={() => this.onReset()}
                    >
                      取消
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className="table">
          <Space className="table-title" align="baseline">
            <div className='table-title-text'>当前发布挑战</div>
            <Button
              type="primary"
              onClick={() => this.onSaveSort()}
              loading={this.state.saveSortButtonLoading}
            >
              保存
            </Button>
            {/* <Button onClick={() => create_data()}>
              debug: Create Objects
            </Button> */}
          </Space>
          <Table
            pagination={false}
            scroll={{
              y:true
            }}
            columns={this.TABLE_COLUMNS}
            dataSource={tableData}
            rowKey="index"
            className='table'
            loading={!this.state.loaded}
            components={{
              body: {
                wrapper: DraggableContainer,
                row: this.DraggableBodyRow,
              },
            }}
          />
        </div>
      </div>
    );
  }
}

export default ChallengePage;
