import React from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN'
import './css/App.css';
import SideBar from './components/sidebar';
import OverviewPage from './pages/overview';
import TopicPage from './pages/topic';
import ChallengePage from './pages/challenge';
import BannerPage from "./pages/banner";
import GalleryManagePage from "./pages/gallery_manage";
import GalleryPubPage from "./pages/gallery_pub";
import ReportPoolPage from "./pages/report_pool";
import ReportPoolDetailPage from "./pages/report_pool_detail";
import HistoryProcessingPage from "./pages/history_processing";
import NewMessagePage from './pages/new_message'
import HistoryMessagePage from './pages/history_message'
import Test from "./pages/Test";
import {appKey, appId, masterKey} from "./setting";
import HistoryProcessingDetailPage from "./pages/history_processing_detail";
import UserManagement from "./pages/user_management";

//initialize leancloud storage from global
const AV = require('leancloud-storage/live-query');

(function () {
  //database info, can be replaced to your own for testing
  AV.init({
    appId:appId,
    appKey:appKey,
  })

}());

AV.debug.enable();
AV.debug.disable();

const App =withRouter(({history})=>{
  return (

      <ConfigProvider locale={zhCN}>

          <SideBar history={history}/>

          <Switch>
            <Route path="/overview">
              <OverviewPage />
            </Route>
            <Route path="/topic">
              <TopicPage />
            </Route>
            <Route path="/challenge">
              <ChallengePage />
            </Route>
            <Route path="/banner">
              <BannerPage />
            </Route>
            <Route path="/gallery_manage">
              <GalleryManagePage />
            </Route>
            <Route path="/gallery_pub">
              <GalleryPubPage />
            </Route>
            <Route exact path="/reportpool">
              <ReportPoolPage />
            </Route>
            <Route path="/reportpool/:id">
              <ReportPoolDetailPage />
            </Route>
            <Route path="/message/new">
              <NewMessagePage />
            </Route>
            <Route path="/message/history">
              <HistoryMessagePage />
            </Route>
            <Route exact path="/historyprocessing">
              <HistoryProcessingPage />
            </Route>
            <Route path="/historyprocessing/:id">
              <HistoryProcessingDetailPage />
            </Route>
            <Route exact path="/test">
              <Test />
            </Route>
            <Route path="/usermanagement">
              <UserManagement/>
            </Route>
          </Switch>

      </ConfigProvider>
  );

})

export default App;
