import React from 'react';
import { Table, Modal, message } from 'antd';
import { getData, getMessageDetails } from '../services/history_message_service';
import { timeFormat } from '../functions/time_format'

import '../css/history_message.css';

const COLUMNS = [
  {
    title: '标题',
    dataIndex: 'title'
  },
  {
    title: '发送对象',
    dataIndex: 'rcvgroup',
    render: group => {
      switch(group) {
        case(0): return('全体用户');
        case(1): return('手动录入');
        default: return('手动录入');
      }
    }
  },
  {
    title: '起始时间',
    dataIndex: 'start_date',
    render: date => {
      if (!date) {return '全时间段'}
      else {return timeFormat(date)}
    }
  },
  {
    title: '终止时间',
    dataIndex: 'end_date',
    render: date => {
      if (!date) {return '全时间段'}
      else {return timeFormat(date)}  // 全时间段的展示方式并没有在原型中画出，因此暂时这样展示。
    }
  },
  // {
  //   title: '状态',  // 状态列在新的数据表定义中被删除了
  //   dataIndex: 'status'
  // },
  {
    dataIndex: 'id',
    className: 'no-display'
  }
];


class DetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      detailsData: null,
      modalContent: "加载中……",
      modalTitle: null
    };
  }

  async componentDidUpdate(prevProps) {
    console.log('Component updated');
    console.log('prevProps', prevProps);
    console.log('current props', this.props);
    if (prevProps.objectId != this.props.objectId) {
      this.setState({
        detailsData: null,
        modalContent: '加载中……',
        modalTitle: null
      });
      if (this.props.visible) {
        await this.getDetailsData();
      }
    }
  }

  async getDetailsData() {
    const id = this.props.objectId;
    if (id === null || id === undefined) {
      return;
    } else {
      console.log('Getting details data of', id);
      const messageDetails = await getMessageDetails(id);
      this.setState({
        detailsData: messageDetails,
        modalContent: messageDetails.content.split('\n').map(
          (line) => <p>{line}<br/></p>  // 将消息正文根据换行符分割成行
        ),
        modalTitle: (
          <div className='message-modal-title'>
            <p><b>
              {messageDetails.title}
            </b></p>
            <p>
              发送对象：{
                messageDetails.rcvgroup? (messageDetails.receiver.split(';').join(', ')) : '全体用户'
              } ({
                messageDetails.all_date? '全时间段' : (timeFormat(messageDetails.start_date) + ' - ' + timeFormat(messageDetails.end_date))  // 统计时间段
              })
            </p>
            {/* 没有写原型中绘制的“发送时间”和“发送状态”，因为数据表文档中没有定义。 */}
          </div>
        )
      });
      console.log('messageDetails', messageDetails);
    }
  }

  render() {
    const { objectId, visible, onCancel } = this.props;
    if (objectId === null || objectId === undefined || !visible) {
      return null;  // 不需要显示的时候，返回null
    }

    return (
      <Modal
        className='message-modal'
        visible={visible}
        width={1000}
        centered
        footer={null}
        title={this.state.modalTitle}
        onCancel={onCancel}
      >
        <div className='message-modal-content'>
          {this.state.modalContent}
        </div>
      </Modal>
    );
  }
}

class HistoryMessagePage extends React.Component {  
  state = {
    tableData: null,
    showModal: false,
    showingDetailId: null,
    tableLoading: true,
  };

  async componentDidMount() {
    const processedData = await getData().catch(error => message.error(error));
    console.log(processedData);
    this.setState({tableData: processedData, tableLoading: false});
  }

  onClickRow(row) {
    console.log('row clicked:', row);
    this.setState({
      showModal: true,  // 显示对话框，并
      showingDetailId: row.objectId  // 传递相应行的id
    });
  }

  render() {
    return (
      <div className="history-message-container">

        <Table 
          columns={COLUMNS}
          dataSource={this.state.tableData} 
          loading={this.state.tableLoading}
          onRow={record=> {
            return {
              onClick: () => this.onClickRow(record),
            }
          }}
          style={{cursor: "pointer"}}
        />

        <DetailsModal 
          objectId={this.state.showingDetailId}
          visible={this.state.showModal}
          onCancel={() => this.setState({showModal: false})}
        />

      </div>
    );
  }
}

export default HistoryMessagePage;
