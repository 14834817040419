import React from 'react';
import { Form, Button, Space, DatePicker} from 'antd';
import "../css/overview.css";
import LineEcharts from '../components/echarts'


const { RangePicker } = DatePicker;


class OverviewPage extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
	    data1: {
	      x: ['2019/09/01', '2019/09/02', '2019/09/03', '2019/09/04', '2019/09/05', '2019/09/06','2019/09/07', '2019/09/08', '2019/09/09', '2019/09/10', '2019/09/11', '2019/09/12','2019/09/13', '2019/09/14', '2019/09/15', '2019/09/16', '2019/09/17', '2019/09/18'],
	      y: [10, 20, 30, 40, 45, 55, 60, 70, 71, 76, 85, 95, 100, 200, 230, 240, 245, 355]
      },
      data2: {
	      x: ['2019/09/15', '2019/09/16', '2019/09/17', '2019/09/18'],
	      y: [5, 9, 6, 15]
      },
      data3: {
	      x: ['2020/10/01', '2020/10/02', '2020/10/03', '2020/10/04', '2020/10/05', '2020/10/06', '2020/10/07'],
	      y: [5, 10, 15, 18, 30, 35, 49]
      },
      data4: {
	      x: ['2020/11/15', '2020/11/16', '2020/11/17', '2020/11/18', '2020/11/19', '2020/11/20'],
	      y: [5, 8, 16, 19, 20,25]
	    }
    }
  }
  componentDidMount() { }

  render() {
    return(
      
      <div class="overview_container">
      <div class="overview_form">
        
        <Form>

          <Form.Item label="注册用户" name="registered">
          <RangePicker />
              {/* <Space> */}
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              {/* </Space> */}
              <LineEcharts data={this.state.data1} yname="注册用户数" />  
          </Form.Item>

        </Form>
      </div>

      <div className="overview_form">
        
        <Form>

          <Form.Item label="活跃用户" name="registered">
          <RangePicker />
              <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              </Space>
              <LineEcharts data={this.state.data2} yname="活跃用户数" />  
          </Form.Item>

        </Form>
      </div>

      <div className="overview_form">
        
        <Form>

          <Form.Item label="发布内容" name="registered">
          <RangePicker />
              <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              </Space>
              <LineEcharts data={this.state.data3} yname="发布内容数" />  
          </Form.Item>

        </Form>
      </div>

      <div className="overview_form">
        
        <Form>

          <Form.Item label="活动参与" name="registered">
          <RangePicker />
              <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              </Space>
              <LineEcharts data={this.state.data4} yname="活动参与数" />  
          </Form.Item>

        </Form>
      </div>
    </div>

    
    ); 
  }
}

export default OverviewPage; 

