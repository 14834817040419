//author: Mingyang SUN , oct 10 2020
import React, {Component} from 'react';
import "../css/report_pool_diary.css";
const AV=require("leancloud-storage");


class ReportPoolDiary extends Component {
    constructor(props){
        super(props);
        this.state={
            data:"",
            error:""
        }
    }
    componentDidMount() {
        this.getData();
    }

    getData= async ()=>{
        const {data,contentId}=this.props;
        if (data===null || contentId===null){
            this.setState({error:"Error in getting content from database"})
        } else{
            const query = new AV.Query('Diary');
            await query.get(contentId)
                .then((msg)=>{
                    this.setState({data:msg},()=>{
                        // console.log(this.state.data)
                    })
                })
                .catch((e)=>{
                    console.log("something wrong in getting creation"+e)
                    this.setState({error:"Error in getting content from database"})
                })
        }


    }

    render() {
        //content from parent component
        const data= this.state.data["_serverData"];
        const {error}=this.state;
        return (
            <div className="report_diary_container">
                { data||"".length>0?(
                    <>
                        <div className="report_diary_title">
                            {data.title?data.title:"no title in here"}
                        </div>
                        <div className="report-pool-diary-content" dangerouslySetInnerHTML={{__html:data.content?data.content:"no content in here"}}/>
                    </>
                    ):error
                    ? error
                    :"loading"}
            </div>
        );
    }
}

export default ReportPoolDiary;
